var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.showDialog,"width":"60%","modal-append-to-body":true,"append-to-body":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.showDialog=$event},"close":_vm.close}},[_c('div',[_c('el-form',{ref:"ruleForm",attrs:{"label-width":"80px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"通知標題","prop":"title"}},[_c('el-input',{attrs:{"placeholder":"请输入通知標題，10字以內"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"通知標題(English)","prop":"title_en"}},[_c('el-input',{attrs:{"placeholder":"Please enter the content"},model:{value:(_vm.form.title_en),callback:function ($$v) {_vm.$set(_vm.form, "title_en", $$v)},expression:"form.title_en"}})],1),_c('el-form-item',{attrs:{"label":"通知內容","prop":"content"}},[_c('editor',{attrs:{"placeholder":"请輸入通知文本內容，150字以內","defaultInit":{
          height: 160,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor code'
          ],
          toolbar:
            'undo redo | link unlink | bold italic | code'
        }},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('el-form-item',{attrs:{"label":"通知內容(English)","prop":"content_en"}},[_c('editor',{attrs:{"placeholder":"Please enter the content, limited to 150 words.","defaultInit":{
          height: 160,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor code'
          ],
          toolbar:
            'undo redo | link unlink | bold italic | code'
        }},model:{value:(_vm.form.content_en),callback:function ($$v) {_vm.$set(_vm.form, "content_en", $$v)},expression:"form.content_en"}})],1),_c('el-form-item',{attrs:{"label":"通知對象","prop":"type"}},[_c('el-radio',{attrs:{"label":1},on:{"change":_vm.changeType},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_vm._v("所有用戶")]),_c('el-radio',{attrs:{"label":2},on:{"change":_vm.changeType},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_vm._v("指定用戶")]),(_vm.form.type == 2)?_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.openMultipleSelectUser}},[_vm._v("添加用戶")]),_vm._v(" "+_vm._s(_vm.selectUids)+" ")],1):_vm._e()],1)],1),_c('multipleSelectUsers',{attrs:{"show":_vm.showInnerDialog,"value":_vm.selectList},on:{"close":function($event){_vm.showInnerDialog = false},"confirm":_vm.confirmSelectUsers}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("發 佈")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }