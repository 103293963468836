<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-11 19:47:09
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-12 18:14:58
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="40%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div v-loading="loading">
      <el-form label-width="80px" :model="form">
        <el-form-item label="通知標題">
          {{ form.title }}
        </el-form-item>
        <el-form-item label="通知標題(English)">
          {{ form.title_en }}
        </el-form-item>
        <el-form-item label="通知內容">
          {{ form.content }}
        </el-form-item>
        <el-form-item label="通知内容(English)">
          {{ form.content_en }}
        </el-form-item>
        <el-form-item label="通知對象">
          {{ form.user }}
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()">確 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      title: "通知詳情",
      showDialog: false,
      form: {},
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        this.previewNote()
      }
    },
  },
  methods: {
    async previewNote() {
      this.loading = true
      let res = await this.api.user.previewNote(this.item.id)
      if (res && res.code == 0) {
        this.form = { ...this.form, ...res.data }
      }
      this.loading = false
    },
    close() {
      this.$emit("close")
    },
    confirm() {
      this.$emit("close")
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  line-height: 1.2;
  align-items: center;
  display: flex;
  height: 40px;
}
</style>
