export const calTableHeight = (self) => {
  let mainPad = 20, // el-main的内边距
    btmHeight = self.$refs["btmDom"] ? self.$refs["btmDom"].offsetHeight : 52, //分页高度
    topHeight = self.$refs["topDom"] ? self.$refs["topDom"].offsetHeight: 100,
    mainHeight = self.$refs["main"].offsetHeight;
    btmHeight = btmHeight < 52 ? 52 : btmHeight;
  let tableHeight =
    mainHeight -
    topHeight -
    btmHeight -
    mainPad;
    // console.log( btmHeight, topHeight, mainHeight, tableHeight)
  return tableHeight;
}

export const calConHeight = (self) => {
  let mainPad = 20, // el-main的内边距
    btmHeight = self.$refs["btmDom"] ? self.$refs["btmDom"].offsetHeight : 0, //分页高度
    topHeight = self.$refs["topDom"] ? self.$refs["topDom"].offsetHeight: 0,
    mainHeight = self.$refs["main"].offsetHeight;
    btmHeight = btmHeight < 52 ? 0 : btmHeight;
  let tableHeight =
    mainHeight -
    topHeight -
    btmHeight -
    mainPad;
    // console.log( btmHeight, topHeight, mainHeight, tableHeight)
  return tableHeight;
}