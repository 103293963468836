<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 18:19:45
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-14 15:20:37
-->
<template>
  <el-dialog :title="title" :visible.sync="showDialog" width="60%" :modal-append-to-body="true" :append-to-body="true"
    :close-on-click-modal="false" @close="close">
    <div>
      <el-form label-width="80px" :model="form" ref="ruleForm">
        <el-form-item label="通知標題" prop="title">
          <el-input v-model="form.title" placeholder="请输入通知標題，10字以內"></el-input>
        </el-form-item>
        <el-form-item label="通知標題(English)" prop="title_en">
          <el-input v-model="form.title_en" placeholder="Please enter the content"></el-input>
        </el-form-item>
        <el-form-item label="通知內容" prop="content">
          <editor v-model="form.content" placeholder="请輸入通知文本內容，150字以內" :defaultInit="{
            height: 160,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor code'
            ],
            toolbar:
              'undo redo | link unlink | bold italic | code'
          }" />
          <!-- <el-input type="textarea" v-model="form.content" class="h100" placeholder="请輸入通知文本內容，150字以內"></el-input> -->
        </el-form-item>
        <el-form-item label="通知內容(English)" prop="content_en">
          <editor v-model="form.content_en" placeholder="Please enter the content, limited to 150 words." :defaultInit="{
            height: 160,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor code'
            ],
            toolbar:
              'undo redo | link unlink | bold italic | code'
          }" />
          <!-- <el-input type="textarea" v-model="form.content_en" class="h100" placeholder="Please enter the content, limited to 150 words."></el-input> -->
        </el-form-item>
        <el-form-item label="通知對象" prop="type">
          <!-- 通知對象,1.所有用户,user为空; 2.指定用户，user: 1,2,3 -->
          <el-radio v-model="form.type" :label="1" @change="changeType">所有用戶</el-radio>
          <el-radio v-model="form.type" :label="2" @change="changeType">指定用戶</el-radio>
          <div v-if="form.type == 2">
            <el-button type="primary" @click="openMultipleSelectUser">添加用戶</el-button>
            <!-- {{ form.user }} -->
            {{ selectUids }}
          </div>
        </el-form-item>
      </el-form>
      <multipleSelectUsers :show="showInnerDialog" :value="selectList" @close="showInnerDialog = false"
        @confirm="confirmSelectUsers"></multipleSelectUsers>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="loading">發 佈</el-button>
    </span>
  </el-dialog>
</template>
<script>
import multipleSelectUsers from "./multipleSelectUsers.vue"
import Tinymce from "@/components/tinymce"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "create",
    },
  },
  components: { multipleSelectUsers, 'editor': Tinymce },
  data() {
    return {
      showInnerDialog: false,
      loading: false,
      title: "發佈通知",
      showDialog: false,
      form: {
        title: "",
        title_en: "",
        content: "",
        content_en: "",
        type: 1,
        user: "",
      },
      selectList: [],
      selectUids: "",
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        //
        this.loading = false
        this.selectList = []
      }
    },
  },
  methods: {
    changeType(v) {
      //
    },
    confirmSelectUsers(val) {
      let ids = []
      let uids = []
      this.selectList = val
      val.forEach((item) => {
        ids.push(item.id)
        uids.push(item.realname)
      })
      this.selectUids = uids.join(",")
      this.form.user = ids.join(",")
    },
    openMultipleSelectUser() {
      this.showInnerDialog = true
    },
    close() {
      this.$refs["ruleForm"].resetFields()
      this.$emit("close")
    },
    resetFields() {
      this.$refs["ruleForm"].resetFields()
    },
    confirm() {
      this.loading = true
      if (this.form.type == 1) {
        this.form.user = ""
      }
      this.$emit("confirm", this.form)
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  line-height: 1.2;
  align-items: center;
  display: flex;
  height: 40px;
}
</style>
