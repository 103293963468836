<template>
  <div>
    <el-dialog
      title="添加用戶"
      :visible.sync="showDialog"
      width="800px"
      :modal-append-to-body="false"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="close"
    >
      <div class="dialog-body">
        <div class="flex-bt m-bar">
          <div>
            <el-input
              size="small"
              class="w300 mr10"
              v-model="filters.title"
              placeholder="请输入用戶名稱/ID/手機號碼"
              clearable
              @clear="search"
              @keyup.enter.native="search"
            ></el-input>
            <el-button type="primary" size="small" @click="search"
              >搜索</el-button
            >
          </div>
        </div>
        <div class="m-records-wrap" v-loading="loadList">
          <div class="m-records-list">
            <div class="pl10" v-show="!showSelectedBox">
              <table class="el-table" cellspacing="0" cellpadding="0">
                <thead>
                  <td width="50">
                    <el-checkbox
                      v-model="checkedAll"
                      @change="toggleCheckAll"
                    ></el-checkbox>
                  </td>
                  <td width="200">用戶ID</td>
                  <td width="80">用戶名稱</td>
                </thead>
                <tbody style="height:240px">
                  <tr v-for="item in tableData.data" :key="item.id">
                    <td width="50">
                      <el-checkbox
                        v-model="item.checked"
                        @change="toggleCheck(item)"
                      ></el-checkbox>
                    </td>
                    <td width="200">
                      <div class="thidden w200">{{ item.realname }}</div>
                    </td>
                    <td width="80">{{ item.nickname }}</td>
                  </tr>
                </tbody>
              </table>
              <el-pagination
                class="m-records-page"
                hide-on-single-page
                :page-size="tableData.per_page"
                :total="tableData.total"
                @current-change="changePage"
              ></el-pagination>
            </div>
            <div class="pl10" v-show="showSelectedBox">
              <table class="el-table" cellspacing="0" cellpadding="0">
                <thead>
                  <td width="200">用戶ID</td>
                  <td width="80">用戶名稱</td>
                  <td>操作</td>
                </thead>
                <tbody style="height:240px">
                  <tr v-for="item in val" :key="item.id">
                    <td width="200">
                      <div class="thidden w200">{{ item.realname }}</div>
                    </td>
                    <td width="80">{{ item.nickname }}</td>
                    <td>
                      <el-popconfirm
                        confirmButtonText="确定"
                        cancelButtonText="取消"
                        icon="el-icon-info"
                        iconColor="red"
                        title="確定刪除嗎？"
                        @onConfirm="delItem(item.id)"
                      >
                        <el-link type="danger" slot="reference">刪除</el-link>
                      </el-popconfirm>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close()">取 消</el-button>
        <el-button type="primary" @click="confirm()">確 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    /* 初始值，选中的条目 */
    value: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      showDialog: false,
      showSelectedBox: false,
      val: [], //结果值
      selectedList: [],
      checkedAll: false,
      loadList: false,
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      filters: {
        title: "",
      },
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      if (val) {
        this.form = { ...this.item }
        this.init()
      }
    },
  },
  methods: {
    async getNoteUsers(page = 1) {
      this.loadList = true
      let res = await this.api.user.getNoteUsers({title: this.filters.title, page})
      if (res && res.code == 0) {
        this.tableData = res.data
        // 初始选中
        this.setSelect()
      }
      this.loadList = false
    },
    /**
     * @method: init 初始化筛选条件和文章数据
     */
    init() {
      this.getNoteUsers()
      this.val = [...this.value]
    },
    /**
     * @method: setSelect 初始化选中列表
     */
    setSelect() {
      this.checkedAll = false
      let ids = [],
        total = 0,
        ln = this.tableData.data.length
      this.val.forEach((item) => {
        ids.push(item.id)
      })
      // 添加选择状态
      this.tableData.data.forEach((item) => {
        if (ids.indexOf(item.id) > -1) {
          item.checked = true
          total++
        } else {
          item.checked = false
        }
      })
      if (total == ln && ln > 0) {
        this.checkedAll = true
      }
    },
    /**
     * @method: toggleCheck 切换选中状态
     */
    toggleCheck(item) {
      // 判断是否全选中
      let ln = this.tableData.data.length
      this.tableData.data.forEach((item) => {
        if (item.checked) {
          ln--
        }
      })
      if (ln == 0) {
        this.checkedAll = true
      } else {
        this.checkedAll = false
      }
      if (item.checked) {
        let has = false
        this.val.forEach((v) => {
          if (v.id == item.id) {
            has = true
          }
        })
        if (!has) {
          this.val.push(item)
        }
      } else {
        this.val.forEach((v, i) => {
          if (v.id == item.id) {
            this.val.splice(i, 1)
          }
        })
      }
    },
    /**
     * @method: toggleCheckAll 全选
     */
    toggleCheckAll() {
      this.tableData.data.forEach((item) => {
        item.checked = this.checkedAll
      })
      let ids = []
      this.val.forEach((item) => {
        ids.push(item.id)
      })
      let cancelIds = []
      this.tableData.data.forEach((item) => {
        cancelIds.push(item.id)
      })
      if (this.checkedAll) {
        this.tableData.data.forEach((item) => {
          if (ids.indexOf(item.id) == -1) {
            this.val.push(item)
          }
        })
      } else {
        for (let i = this.val.length - 1; i > -1; i--) {
          if (cancelIds.indexOf(this.val[i].id) > -1) {
            this.val.splice(i, 1)
          }
        }
      }
    },
    /**
     * @method: delItem 已选择的可以删除
     */
    delItem(id) {
      this.val.forEach((item, k) => {
        if (item.id == id) {
          this.val.splice(k, 1)
        }
      })
      this.tableData.data.forEach((item) => {
        if (item.id == id) {
          item.checked = false
        }
      })
    },
    /**
     * @method: search 根据条件筛选文章
     */
    search() {
      this.getNoteUsers()
    },
    /**
     * @method: changePage 切换页码
     * @param: pageNum {Number} 页码
     */
    changePage(pageNum) {
      this.getNoteUsers(pageNum)
    },
    /**
     * @method: showSelected 显示已经选中的列表
     */
    showSelected() {
      this.categoryId = -1
      this.showSelectedBox = true
    },
    close() {
      this.$emit("close")
    },
    confirm() {
      this.close()
      this.$emit("confirm", this.val)
    },
  },
}
</script>
<style scoped>
table tbody {
  display: block;
  height: 500px;
  overflow-y: scroll;
}

table thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
