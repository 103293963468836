<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l lhx40">
        <el-input
          class="w300 mr10 mb20"
          placeholder="请输入通知标题"
          clearable
          v-model="filters.title"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <div>
          <el-button type="primary" @click="search">搜尋</el-button>
          <el-button type="primary" @click="openCreateDialog"
            >發佈通知</el-button
          >
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="通知標題" prop="title"></el-table-column>
          <el-table-column
            prop="title_en"
            label="通知標題（English)"
            width="200"
          ></el-table-column>
          <el-table-column label="通知用戶數量" prop="num"></el-table-column>
          <el-table-column label="通知時間" width="140">
            <template slot-scope="scope">
              {{ scope.row.create_time | formatTime }}
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" @click="preview(scope.$index)"
                >查看</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <createNoteDialog
      :show="showDialog"
      @close="showDialog = false"
      @confirm="confirm"
      :type="type"
      ref="createNote"
    ></createNoteDialog>
    <previewNoteDialog
      :show="showPreview"
      :item="curItem"
      @close="showPreview = false"
    ></previewNoteDialog>
  </div>
</template>
<script>
import createNoteDialog from "./components/createNoteDialog.vue"
import previewNoteDialog from "./components/previewNoteDialog.vue"
import { calTableHeight } from "@/common/tool"
export default {
  components: { createNoteDialog, previewNoteDialog },
  data() {
    return {
      loadList: false,
      curItem: {},
      curIndex: -1,
      type: "create",
      showDialog: false,
      showPreview: false,
      filters: {
        title: "",
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.user.getNotes({ page, ...this.filters })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    /**
     * @method: resetFilters 重置筛选条件
     */
    resetFilters() {
      this.filters = {
        title: "",
      }
    },
    openCreateDialog() {
      this.type = "create"
      this.showDialog = true
    },
    preview(index) {
      this.curIndex = index
      this.curItem = this.tableData.data[index]
      this.showPreview = true
    },
    confirm(data) {
      if (this.type == "create") {
        this.publishNote(data)
      } else {
        this.showDialog = false
      }
    },
    async publishNote(data) {
      let res = await this.api.user.publishNote(data)
      if (res && res.code == 0) {
        this.$message({
          message: "通知發佈成功",
          type: "success",
        })
        this.showDialog = false
        this.$refs['createNote'].resetFields()
        this.getDataList()
      }
      this.$refs['createNote'].loading = false
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    togglePublish(value, index) {
      if (value == 1) {
        this.unPublish(index)
      } else {
        this.publish(index)
      }
    },
    async unPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.ads.switchHomeIndex(id, 1)
      if (res && res.code == 0) {
        console.log(res.data)
        // this.tableData.data[index].show = 1;
        this.$message({
          message: "隱藏成功",
          type: "success",
        })
      }
    },
    async publish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.ads.switchHomeIndex(id, 0)
      if (res && res.code == 0) {
        // this.tableData.data[index].show = 0;
        this.$message({
          message: "顯示成功",
          type: "success",
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.c-note {
  line-height: 20px;
  overflow: hidden;
  height: 40px;
}
</style>
